import * as selectOptions from "@/helpers/selectOptions";
import { get } from "lodash";
import isBannedColumn from "./isBannedColumn.vue";
import isRestrictedColumn from "./IsRestrictedColumn.vue";
import UserCodeColumn from "./UserCodeColumn.vue";

export const tableColumnsListUsers = {
  allAgencyCodes: {
    formatDataKey: "Agent/Adjuster Code",
    formatDataValue: (arg: any) =>
      typeof arg === "object" ? arg.toString() : arg,
    formatDataComponent: UserCodeColumn,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 8,
    colWidth: 200,
    sortMethod: (a: any, b: any) => {
      const aCode = get(a, "allAgencyCodes", []).toString();
      const bCode = get(b, "allAgencyCodes", []).toString();
      return aCode.localeCompare(bCode, undefined, {
        sensitivity: "base"
      });
    }
  },
  firstName: {
    formatDataKey: "First Name",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1,
    colWidth: 170
  },
  lastName: {
    formatDataKey: "Last Name",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 2,
    colWidth: 160
  },
  email: {
    formatDataKey: "Email",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 3
  },
  role: {
    formatDataKey: "Role",
    formatDataValue: (arg: string) =>
      arg.charAt(0).toUpperCase() + arg.slice(1),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    simpleFilter: selectOptions.userRoles,
    position: 4,
    colWidth: 130
  },
  status: {
    formatDataKey: "Status",
    formatDataValue: (arg: string) =>
      arg.replace(/([A-Z])/g, " $1").replace(/^./, function(str) {
        return str.toUpperCase();
      }),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    simpleFilter: selectOptions.userStatus,
    position: 5,
    colWidth: 130
  },
  isBanned: {
    formatDataKey: "Banned",
    formatDataValue: (arg: string) => arg,
    formatDataComponent: isBannedColumn,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    simpleFilter: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" }
    ],
    colWidth: 100,
    position: 6
  },
  isRestricted: {
    formatDataKey: "Restricted",
    formatDataValue: (arg: string) => arg,
    formatDataComponent: isRestrictedColumn,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: false,
    colWidth: 125,
    position: 7
  }
};
