
import Vue from "vue";
export default Vue.extend({
  name: "user-code-column",
  props: {
    row: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  computed: {
    userCode(): string {
      if (this.row.role === "adjuster") {
        return this.row.adjusterCode;
      } else if (typeof this.row.allAgencyCodes === "object") {
        if (this.showToolTip) {
          return `${this.row.allAgencyCodes.slice(0, 5).toString()}.....`;
        }
        return this.row.allAgencyCodes.toString();
      } else {
        return this.row.allAgencyCodes;
      }
    },
    showToolTip(): boolean {
      return this.row.allAgencyCodes && this.row.allAgencyCodes.length > 5;
    },
    showAllAgencyCodes(): string {
      const allAgencyCodes = this.row.allAgencyCodes;

      if (Array.isArray(allAgencyCodes) && allAgencyCodes.length > 5) {
        return allAgencyCodes
          .reduce((result, code, index) => {
            if (index % 3 === 0) {
              result.push(allAgencyCodes.slice(index, index + 3).join(", "));
            }
            return result;
          }, [])
          .join("<br />");
      } else {
        return allAgencyCodes.toString();
      }
    }
  }
});
