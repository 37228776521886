
import Vue from "vue";

import UploadArea from "@/components/UploadArea/UploadArea.vue";
import { downloadImportFileTemplate } from "@/helpers";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";

interface IData {
  message: string;
  error: string;
}

export default Vue.extend({
  name: "banned-or-restricted",
  props: {
    legend: {
      type: String,
      required: false,
      default: ""
    },
    url: {
      type: String,
      required: false,
      default: ""
    },
    importTemplateCollection: {
      type: String,
      required: false,
      default: ""
    },
    showHeading: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    UploadArea,
    CustomAlert
  },
  data(): IData {
    return {
      message: "",
      error: ""
    };
  },
  methods: {
    onFileUploadSuccess(result: any) {
      this.$appNotifySuccess("File Upload Success");
      this.$emit("upload-success", result);
      this.$emit("close");
    },
    onFileUploadError(err: any) {
      this.$emit("error", err);
      this.error = err.response.message;
    },
    downloadImportTemplateHandler() {
      downloadImportFileTemplate(this.importTemplateCollection);
    }
  }
});
