
import Vue from "vue";
export default Vue.extend({
  name: "is-banned-column",
  props: {
    row: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  computed: {
    indicatorClass(): string {
      return this.row.isBanned ? "bg-red-500" : "bg-green-500";
    },
    title(): string {
      return this.row.isBanned ? "YES" : "NO";
    },
    type(): string {
      return this.row.isBanned ? "danger" : "success";
    }
  }
});
