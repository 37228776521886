
import Vue from "vue";
import { differenceInDays, parseISO } from "date-fns";
export default Vue.extend({
  name: "is-banned-column",
  props: {
    row: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  methods: {
    dayNoun(days: number) {
      return days > 1 ? "days" : "day";
    }
  },
  computed: {
    indicatorClass(): string {
      return this.row.isBanned ? "bg-red-500" : "bg-green-500";
    },
    title(): string {
      return this.isRestricted ? "YES" : "NO";
    },
    type(): string {
      return this.isRestricted ? "danger" : "success";
    },
    isRestricted(): boolean {
      return !!(
        this.row.isRestricted &&
        Object.keys(this.row.isRestricted).length &&
        differenceInDays(
          parseISO(this.row.isRestricted.startDate),
          new Date()
        ) >= 0 &&
        differenceInDays(parseISO(this.row.isRestricted.endDate), new Date()) >=
          0
      );
    },
    totalRestrictedDays(): number {
      return differenceInDays(
        parseISO(this.row.isRestricted.endDate),
        parseISO(this.row.isRestricted.startDate)
      );
    },
    restrictedToolTipContent(): string {
      return `Restricted for ${this.totalRestrictedDays} ${this.dayNoun(
        this.totalRestrictedDays
      )}`;
    }
  }
});
